import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup'
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import DesktopDateTimePicker from '@mui/lab/DesktopDateTimePicker';
import Stack from '@mui/material/Stack';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90vh',
    width: '90vw',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: "scroll"
};

function checkboxOptionsView(key, props) {

    const { form, setForm, offerings = {} } = props

    let offeringList = key in offerings ? offerings[key] : []

    return offeringList.length === 0 ? null :
        (
            <FormControl variant="outlined">
                <FormLabel component="legend">{form[key].label}</FormLabel>

                <Grid container={true} item>
                    {offeringList.map(option => {
                        return (<Grid
                            item
                            key={option}
                            xs={6}
                            sm={6}
                            md={3}
                        ><FormControlLabel control={<Checkbox
                            checked={form[key].value[option]}
                            onChange={() => {

                                setForm((form) => { return { ...form, [key]: { ...form[key], value: { ...form[key].value, [option]: !form[key].value[option] } } } })

                            }
                            } />} label={option} />
                        </Grid>)
                    })}

                </Grid>
            </FormControl>
        );

}

function SendFormView(props) {

    const { currentPackage, closePackageForm, form, setForm, sendForm, sendStatus, sendStatuses, offerings = {} } = props

    let { packageFormOptions = [] } = currentPackage != null ? currentPackage : {}

    const field = (key) => {
        switch (key) {
            case "date":
                return (
                    <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            renderInput={(props) => <TextField sx={{ mt: 1 }} fullWidth {...props} />}
                            label={form[key].label}
                            fullWidth
                            value={form[key].value}
                            onChange={(newValue) => {

                                setForm((form) => { return { ...form, [key]: { ...form[key], value: newValue } } })

                            }
                            }
                        />
                    </LocalizationProvider>)
            case "activities":
            case "services":
            case "characters":
            case "originalCharacters":
            case "mascotCharacters":
            case "babyShower":
            case "babyShowerCrafts":
            case "festivalActivities":
            case "communityAndCorporateCrafts":

                return packageFormOptions.includes(key) ? checkboxOptionsView(key, props) : null;

            case "notes":
                return (<TextField sx={{ mt: 1 }} label={form[key].label} multiline
                    rows={4}
                    variant="filled" fullWidth value={form[key].value}
                    onChange={({ target: { value: newValue = "" } = {} }) => {

                        setForm((form) => { return { ...form, [key]: { ...form[key], value: newValue } } })

                    }} />)


            default:
                return (<TextField sx={{ mt: 1 }} label={form[key].label} variant="outlined" fullWidth value={form[key].value}
                    onChange={({ target: { value: newValue = "" } = {} }) => {

                        setForm((form) => { return { ...form, [key]: { ...form[key], value: newValue } } })

                    }} />)
        }
    }

    return (
        <Box sx={style}>

            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, mb: 4 }}>
                Send us a request {currentPackage && currentPackage.title ? ("about " + currentPackage.title) : ""} and we'll reach out to you!
            </Typography>

            <Grid container={true} spacing={3} alignItems="center">
                {Object.keys(form).map(key => {
                    var element = field(key)
                    return (
                        element ?
                            <Grid
                                item
                                key={key}
                                xs={12}
                                sm={key in offerings || key === "notes" ? 12 : 6}
                                md={key in offerings || key === "notes" ? 12 : 6}
                            >

                                {element}

                            </Grid> : null
                    )
                })}
            </Grid>

            {sendStatus !== "none" &&
                (<Typography id="modal-modal-title" variant="p" component="p" sx={{ mt: 2, mb: 2 }} textAlign={"center"}>
                    {sendStatuses[sendStatus]}
                </Typography>)
            }

            {/* <div style={{ marginTop: 10, padding: 5, backgroundColor: "#8083EE" }}>Note: We will be out of the office June 27th- June 2nd. We will reply to all messages as soon as we return. Thank you for your understanding. </div> */}

            <Grid container={true} spacing={3} sx={{ mt: 0, mb: 2 }} alignItems="center">

                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                >

                    <Button variant="contained" color="secondary" fullWidth onClick={closePackageForm}>Cancel</Button>

                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                >
                    <Button variant="contained" fullWidth onClick={() => {

                        sendForm()

                    }} >Send</Button>

                </Grid>


            </Grid>
        </Box>
    )
}

function modalView(props) {
    const { sendStatus, sendStatuses, closePackageForm } = props

    if (sendStatus === "sent") {

        return (
            <Box sx={style}><Typography id="modal-modal-title" variant="p" component="p" sx={{ mt: 2, mb: 2 }} textAlign={"center"}>
                {sendStatuses[sendStatus]}
            </Typography>

                <Button variant="contained" color="secondary" fullWidth onClick={closePackageForm}>Close</Button>
            </Box>)

    } else {

        return SendFormView(props)

    }
}

export default function PackageModel(props) {

    const { currentPackage, closePackageForm, form, setForm, sendForm, sendStatus, sendStatuses } = props

    return (
        <Modal
            open={currentPackage != null}
            onClose={closePackageForm}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            {modalView(props)}

        </Modal>
    );
}
