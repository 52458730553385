// based on https://github.com/mui-org/material-ui/blob/master/docs/src/pages/getting-started/templates/pricing/Pricing.tsx
import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { FacebookProvider, Page } from 'react-facebook';

import CustomizedMenu from './componentMenu'

import "./App.css"

import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
// import { InlineWidget } from "react-calendly";

import { TextField } from '@mui/material';


import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, push } from "firebase/database"

import logo from "./logo.png"

import PackageModel from "./PackageForm"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAO1SEkGp2MOdRgvsWgc5QHsbaP9XGIkNU",
  authDomain: "spunkyfantasypartyservices.firebaseapp.com",
  projectId: "spunkyfantasypartyservices",
  storageBucket: "spunkyfantasypartyservices.appspot.com",
  messagingSenderId: "789732807009",
  appId: "1:789732807009:web:ef12d11bd2b70d02283520",
  measurementId: "G-WFMYFWEN8K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#787CF9',
    },
    neutral: {
      main: '#fdbb2d'
    },
  },
  typography: {
    button: {
      fontWeight: "bold"
    },
    h6: {
      fontWeight: "bold"
    }
  }
});

const metadata = require("./metadata.json")

// https://developers.facebook.com/tools/explorer/?method=GET&path=101347391590471%2Fphotos%3Ffields%3Dimages%2Calbum&version=v12.0 
const initialForm = {
  name: { value: "", label: "Name" },
  phone: { value: "", label: "Contact Phone" },
  email: { value: "", label: "Email" },
  event: { value: "", label: "Event" },
  date: { value: Date.now(), label: "Date and Time" },
  numberOfKids: { value: 10, label: "Number of Kids" },
  activities: { value: {}, label: "Activities Requested" },
  services: { value: {}, label: "Services Requested" },
  characters: { value: {}, label: "Characters Requested" },
  originalCharacters: { value: {}, label: "Original Characters Requested" },
  mascotCharacters: { value: {}, label: "Mascot Characters Requested" },
  babyShower: { value: {}, label: "Baby Shower Games" },
  babyShowerCrafts: { value: {}, label: "Baby Shower Crafts" },
  festivalActivities: { value: {}, label: "Festival Activities & Games" },
  communityAndCorporateCrafts: { value: {}, label: "Crafts, Classes & Gaming Stations" },
  notes: { value: "", label: "Other Notes" }
}

let sendStatuses = {
  "none": "",
  "missingName": "Please enter your name!",
  "missingPhoneOrEmail": "Please enter your email OR phone number so we can contact you!",
  "sent": "Your request has been sent! We will get back to you as soon as possible."
}

function ElevationScroll(props) {
  const { children, window, isHideUntilScroll } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  if (!isHideUntilScroll) {
    return React.cloneElement(children)
  }

  return trigger ? React.cloneElement(children) : null;
}

function Main() {

  const [currentPackage, setCurrentPackage] = useState(null);
  const [sendStatus, setSendStatus] = useState("none");

  const [form, setForm] = useState(initialForm)

  const resetForm = () => {
    setForm(initialForm)
  }

  const closePackageForm = () => {
    setSendStatus("none")
    setCurrentPackage(null)
  }

  const sendForm = () => {

    console.log(form)

    if (form.name.value === "") {
      return setSendStatus("missingName")
    }
    if (form.phone.value === "" && form.email.value === "") {
      return setSendStatus("missingPhoneOrEmail")
    }

    var email = ""
    for (let key of Object.keys(form)) {

      var { label, value } = form[key]

      if (key === "date") {
        value = new Date(value)
      } else if (typeof value == "object") {
        // eslint-disable-next-line no-loop-func
        value = Object.keys(value).filter(optionKey => value[optionKey] === true)
      }

      email += `${label}: ${value} \n`

    }

    const db = getDatabase();
    push(ref(db, 'emails'), {
      mail: email,
      form
    });

    setSendStatus("sent")

    resetForm()
  }

  return (
    <React.Fragment>
      <PackageModel currentPackage={currentPackage} offerings={metadata.offerings} closePackageForm={closePackageForm} form={form} setForm={setForm} sendForm={sendForm} sendStatus={sendStatus} sendStatuses={sendStatuses} />
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />

      <AppBar
        color="primary"
        elevation={0}

        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        {/* <ElevationScroll isHideUntilScroll={false}>
          <div style={{ padding: 5 }}>We will be out of the office June 27th- June 2nd. We will reply to all messages as soon as we return. Thank you for your understanding. </div>
        </ElevationScroll> */}
        <ElevationScroll isHideUntilScroll={true}>
          <Toolbar sx={{ flexWrap: 'wrap' }}>
            <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              {metadata.title}
            </Typography>
            <nav>
              {metadata.pages.map((item) => {

                if (item.hasOwnProperty("children")) {
                  return (CustomizedMenu(item))
                } else {
                  return (<Button
                    key={item.label}
                    variant="button"
                    color="neutral"
                    href={item.href}
                    target={item.href.includes("#") ? "_self" : "_blank"}
                    sx={{ my: 1, mx: 1.5 }}
                  >
                    {item.label}
                  </Button>)
                }
              })
              }
            </nav>
          </Toolbar>
        </ElevationScroll>
      </AppBar>
      <header className='imageBG'>


        <Container disableGutters component="main" sx={{ pt: 4, pb: 6 }}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            {/* {metadata.title} */}
            <img src={logo} style={{ width: "min(80vw, 90vh)" }} alt="Spunky Fantasy Party Services" />
          </Typography>
        </Container>


        <Container disableGutters component="main" sx={{ pt: 8, pb: 6 }}>
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="text.primary"
            gutterBottom
          >Party Attendants, Face Painting, Balloon Animals, Clown Parties, Mascots & So Much More!
          </Typography>
        </Container>


        <Container disableGutters component="main" sx={{ pt: 8, pb: 6 }}>

          <ImageList id="homepageImageList" variant="masonry" cols={4} gap={0}>
            {metadata.imageGallery.map((item, index) => (
              <ImageListItem key={item.title + `${index}`}>
                <img
                  src={`${item.src}`}
                  srcSet={`${item.src}`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Container>
      </header>

      <section>
        <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }} id="packages">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Book your event now!
          </Typography>
        </Container>

        <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }} id="private-party-packages">
          <Typography
            component="h3"
            variant="h4"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Private Party Packages
          </Typography>
        </Container>
        <Container maxWidth="md" component="main">
          <Grid container={true} spacing={3} alignItems="flex-end">
            {metadata.private_party_packages.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={6}
                md={4}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: 'center',
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    {tier.price &&
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'baseline',
                          mb: 2,
                        }}
                      >
                        <Typography component="h2" variant="h3" color="text.primary">
                          ${tier.price}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                        </Typography>
                      </Box>
                    }
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth variant={tier.buttonVariant} onClick={() => {
                      setForm((form) => { return { ...form, event: { ...form.event, value: tier.title } } })
                      setCurrentPackage(tier)
                    }}>
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>


        <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }} id="community-corporate-packages">
          <Typography
            component="h3"
            variant="h4"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Community & Corporate Packages
          </Typography>
        </Container>
        <Container maxWidth="md" component="main">
          <Grid container={true} spacing={3} alignItems="flex-end">
            {metadata.community_corporate_packages.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={6}
                md={4}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: 'center',
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    {tier.price &&
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'baseline',
                          mb: 2,
                        }}
                      >
                        <Typography component="h2" variant="h3" color="text.primary">
                          ${tier.price}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                        </Typography>
                      </Box>
                    }
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth variant={tier.buttonVariant} onClick={() => {
                      setForm((form) => { return { ...form, event: { ...form.event, value: tier.title } } })
                      setCurrentPackage(tier)
                    }}>
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }} id="decor">
          <Typography
            component="h3"
            variant="h4"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Balloon Decor
          </Typography>
          <Typography
            component="h5"
            variant="h5"
            align="center"
            color="text.primary"
            gutterBottom
          >
            ($150 minimum)
          </Typography>
        </Container>

        <Container maxWidth="md" component="main">
          <Grid container={true} spacing={3} alignItems="flex-end">
            {metadata.decor.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={6}
                md={4}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: 'center',
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    {tier.price &&
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'baseline',
                          mb: 2,
                        }}
                      >
                        <Typography component="h2" variant="h3" color="text.primary">
                          ${tier.price}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                        </Typography>
                      </Box>
                    }
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth variant={tier.buttonVariant} onClick={() => {
                      setForm((form) => { return { ...form, event: { ...form.event, value: tier.title } } })
                      setCurrentPackage(tier)
                    }}>
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        <Container disableGutters maxWidth="md" component="div" sx={{ pt: 8 }} id="events">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Events
          </Typography>

          <Container sx={{ pt: 8 }} align="center" >
            <FacebookProvider appId="1384852845305296">
              <Page href="https://www.facebook.com/spunkyfantasypartyservices/" tabs="events,timeline,messages" />
            </FacebookProvider>
          </Container>
        </Container>

        <Container disableGutters maxWidth="md" component="main" sx={{ padding: 5, pt: 8, pb: 6 }} id="contact">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Contact us
          </Typography>

          <Container disableGutters component="main" sx={{ pt: 8, pb: 6 }}>
            <Typography
              component="h1"
              variant="h6"
              align="center"
              color="text.primary"
              gutterBottom
            >
              "Leave your preconceived notions at the door and prepare for something you’ve never experienced! I'm in the business of making memories and have been performing as a professional entertainer since 2013. Let me help make your party or event unforgettable. Get in touch with me today to experience a one-of-a-kind performance." - Sam, Owner and Entertainer
            </Typography>
          </Container>

          <Card align="center">
            <Grid container={true} spacing={5} alignItems="center" sx={{ padding: 5, pt: 8, pb: 6 }}>
              <Grid item
                xs={12}
                sm={8}
                md={8}
              >
                <TextField id="contactUsMessage" label="Please tell us what you're interested in" variant="outlined" color="secondary" fullWidth onChange={({ target: { value = "" } = {} }) => {

                  setForm((form) => { return { ...form, notes: { ...form.notes, value } } })

                }} />
              </Grid>
              <Grid item
                xs={12}
                sm={4}
                md={4}
              >
                <Button variant="contained" onClick={() => {

                  setCurrentPackage({})


                }} >Reach out to us!</Button>
              </Grid>
            </Grid>
          </Card>


          <Typography variant="h3" align="center" color="text.secondary" component="p" sx={{ padding: 5, pt: 8, pb: 6 }}>
            Reach us on Social Media
          </Typography>

          <Typography variant="span" display={"inline"} align="center" color="text.secondary" component="span" sx={{ padding: 5, pt: 8, pb: 6 }}>
            <Button
              href="https://www.facebook.com/spunkyfantasypartyservices/"
              target="_blank"
              variant="button"
              startIcon={<FacebookIcon />}
              color="text.primary">  Facebook
            </Button>
          </Typography>

          <Typography variant="span" display={"inline"} align="center" color="text.secondary" component="span" sx={{ padding: 5, pt: 8, pb: 6 }}>
            <Button
              href="mailto:spunkyfantasyservices@gmail.com" target="_blank"
              variant="button"
              startIcon={<EmailIcon />}
              color="text.primary"> Email
            </Button>
          </Typography>

          <Typography variant="span" display={"inline"} align="center" color="text.secondary" component="span" sx={{ padding: 5, pt: 8, pb: 6 }}>
            <Button
              href="tel:9102698560"
              variant="button"
              color="text.primary" startIcon={<LocalPhoneIcon />}>
              (910)269-8560
            </Button>
          </Typography>

        </Container>


        {/* Footer */}
        <Container
          maxWidth="md"
          component="footer"
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            mt: 8,
            py: [3, 6],
          }}
        >
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </section>
      {/* End footer */}
    </React.Fragment>
  );
}

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        {metadata.title}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export default function Pricing() {
  return (
    <ThemeProvider theme={darkTheme}><Main /></ThemeProvider>);
}
